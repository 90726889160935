import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/kenneth/Repos/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hope you enjoyed `}<a href="https://www.meetup.com/typebrigade/events/248972116/">{`Type Brigade `}<span className="lnum">{`№43`}</span></a>{` the other night! We were glad to help kick-off Vancouver Design Week, and have `}<a parentName="p" {...{
        "href": "http://transformexp.com/we-are/"
      }}>{`Angela Bains`}</a>{`, `}<a parentName="p" {...{
        "href": "http://www.deangiffin.com/"
      }}>{`Dean Giffin`}</a>{`, and `}<a parentName="p" {...{
        "href": "http://www.matthewsmithdesign.net/"
      }}>{`Matthew Smith`}</a>{` speak.`}</p>
    <p>{`Here are a few other things we shared during the event:`}</p>
    <h2>{`Martin Jackson Calligraphy Workshop`}</h2>
    <p>{`We also announce our upcoming Calligraphy Fundamentals workshop run by renowned calligrapher Martin Jackson.`}</p>
    <p>{`Our Patreon backers get \\$10 off and `}<a parentName="p" {...{
        "href": "https://www.patreon.com/posts/early-access-to-18666559"
      }}>{`early access to the tickets`}</a>{`, in addition to the other perks we offer like our custom print series printed by `}<a parentName="p" {...{
        "href": "http://www.mitchellpress.com/"
      }}>{`Mitchell Press`}</a>{`.`}</p>
    <p>{`Otherwise, General Admission opens up on Monday, and you can `}<a parentName="p" {...{
        "href": "https://www.picatic.com/typebrigade-calligraphy-3"
      }}>{`sign up for the waitlist now`}</a>{`.`}</p>
    <h2>{`Custom Fonts and CSS on Squarespace`}</h2>
    <p>{`If you’re using Squarespace but want to go beyond the default font selection, I’ve written `}<a parentName="p" {...{
        "href": "http://kennethormandy.com/journal/custom-fonts-and-css-on-squarespace/"
      }}>{`a free guide for you`}</a>{`.`}</p>
    <p>{`There’s also an expanded version available there for less than the price of a single month of Squarespace. You can get 10% off with the promo code `}<inlineCode parentName="p">{`TYPEBRIGADE`}</inlineCode>{`.`}</p>
    <h2>{`Vancouver Design Week`}</h2>
    <p>{`There’s also many more fun and interesting events going on this week for VDW. Here’s some with Type Brigade contributors:`}</p>
    <ul>
      <li parentName="ul">{`Matthew Smith will be running the `}<a parentName="li" {...{
          "href": "https://www.eventbrite.com/e/make-it-pop-quiz-vancouver-design-week-tickets-45249174499"
        }}>{`Make It Pop Quiz tonight`}</a></li>
      <li parentName="ul">{`Angela Bains is `}<a parentName="li" {...{
          "href": "https://docs.google.com/forms/d/1aMg2AMzY6dCXqHjIbbsHMzhILJrccMzkf3A0T25fg70"
        }}>{`speaking at BCIT cocktails event`}</a>{` on Friday, May 11`}</li>
      <li parentName="ul">{`Previous speaker Patrick Altair McDonald and I will be at `}<a parentName="li" {...{
          "href": "https://www.picatic.com/vdwatelier"
        }}>{`the L’Atelier Breakfast`}</a>{` on the morning of May 12`}</li>
      <li parentName="ul">{`BrainStation’s `}<a parentName="li" {...{
          "href": "https://brainstation.io/event/intro-to-user-interface-20184517400"
        }}>{`User Interface Design Principles`}</a>{` event is on May 12, free with code `}<inlineCode parentName="li">{`VDW100`}</inlineCode></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://louderthanten.com/_"
        }}>{`Louder Than Ten`}</a>{` is having an open house on May 12, with previous speaker Travis Gertz`}</li>
      <li parentName="ul">{`Type Brigade co-organiser `}<a parentName="li" {...{
          "href": "https://alannamunro.com/"
        }}>{`Alanna Munro`}</a>{` will be running a lettering demo inside a bubble in Robson Square on May 12 at 3pm`}</li>
    </ul>
    <p>{`The full list of events is on `}<a parentName="p" {...{
        "href": "http://vancouverdesignwk.com/"
      }}>{`the VDW website`}</a>{`.`}</p>
    <p>{`Have a great rest of your Design Week, and hope to see you at Type Brigade №44 on May 30. You’ll get an automatic email via `}<a parentName="p" {...{
        "href": "http://meetup.com/typebrigade"
      }}>{`the meetup group`}</a>{` about that as soon as we officially announce the event.`}</p>
    <p>{`—Kenneth`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      